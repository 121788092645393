/* eslint-disable */
import { HyperPay, Noon, Urway } from 'payment-gateway-server'
const APP_PAYMENT_ALIAS = 'ecom' // Your app payment alias
// eslint-disable-next-line camelcase
const return_url = window.location.origin + '/payment-status' // Your app return url
// const project = "Tuxedo"

export default class Payment {
    constructor (gateway, data = {}) {
        if (!Object.keys(data).length) this.setInitialValue()
        else this.fillData(data)
        this.gateway = gateway
    }

    getPaymentGateway () {
        switch (this.gateway.toLowerCase()) {
            case 'hyperpay':
                return HyperPay('Hosted')
            case 'noon':
                return Noon('Hosted')
            case 'urway':
                return Urway('Hosted')
            default:
                return null
        }
    }

    setInitialValue () {
        this.data = {
            merchant_transaction_id: '', // Your app transaction id || order id
            reference: '', // Your app reference || order id
            track_id: '', // Your app track id || order id
            amount: '',
            name: '',
            currency: 'SAR',
            address: {
                city: '',
                country: '',
                state: '',
                street1: '',
                customer_email: '',
                first_name: '',
                last_name: '',
                postcode: ''
            },
            return_url: return_url
        }
    }

    fillData (data) {
        if (data) {
            console.log('ggggggggg',return_url)
            console.log(data)
            this.data = {
                merchant_transaction_id: `${APP_PAYMENT_ALIAS}${data.orderId}` ?? '',
                reference: `${APP_PAYMENT_ALIAS}${data.orderId}` ?? '',
                track_id: `${APP_PAYMENT_ALIAS}${data.orderId}` ?? '',
                amount: data.amount ?? '',
                name: data.name ?? '',
                currency: 'SAR',
                address: {
                    city: data.city ?? '',
                    country: data.country ?? '',
                    state: data.state ?? '',
                    street1: data.street ?? '',
                    customer_email: data.email ?? '',
                    first_name: data.first_name ?? '',
                    last_name: data.last_name ?? '',
                    postcode: data.postalCode ?? ''
                },
                return_url: return_url
            }
        } else {
            this.setInitialValue()
        }
    }

    async paymentConfig () {
        const PaymentGateway = await this.getPaymentGateway()
        // const res = await PaymentGateway.initPayment(this.data)
        if (this.gateway == 'hyperpay') {const res='https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId='
            // console.log('url is ', res)
            return res
        }
    }

    async registerPayment (payload) {
        const PaymentGateway = await this.getPaymentGateway()
        const res = await PaymentGateway.registerPayment(payload)
        return res
    }
}
