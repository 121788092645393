/* eslint-disable */
import * as $axios from "./Helpers/AxiosInstance";
import {PAYMENT_DEFAULT} from "./Helpers/DefaultPayment";
import {dataGet} from "./Helpers/Utils";
import {MasterCardBuilder} from "./Builders/MasterCardBuilder";
import {Builder} from "./Builders/Builder";

export class PaymentGatewaySDK {
    info = {};
    config = {};
    gateway = undefined;
    gatewayType = undefined;
    axios;

    constructor({gateway = "", gatewayType = "", config = {}}) {
        this.gateway = gateway || PAYMENT_DEFAULT.gateway;
        this.gatewayType = gatewayType || PAYMENT_DEFAULT.type;

        return this.init(config);
    }

    async init(config = {}) {
        return await this.loadInfo()
            .then(x => this.loadConfig()
                .then(x => config ? this.mergeConfig(config) : this));
    }

    async loadInfo(force = false) {
        if (!force && Object.keys(this.info).length > 2) {
            return Promise.resolve(this.info);
        }
        return import('./info.json')
            .then((file) => {
                return import('./config.json')
                    .then((configFile) => {
                        return Object.assign({}, file.default || file || {}, configFile.default || configFile || {});
                    });
            })
            .then(info => {
                this.info = info;
                let {data, key} = dataGet(this.info, [this.info && this.info.debug ? 'base_url_local' : 'base_url', 'base_url'], 'base_url');
                this.info[key] = this.info[key] || '';
                if (data && data.substring(data.length - 1) !== "/") {
                    data = `${data}/`;
                }
                this.info[key] = data;

                // region: currency
                return this.getRegionConfig()
                    .then((regionConfig = {}) => (this.info = {...this.info, ...regionConfig}));
                // endregion: currency
            })
    }

    async loadConfig(gateway = "", force = false) {
        gateway = gateway || this.gateway || PAYMENT_DEFAULT.gateway

        if (!force && Object.keys(this.config).length) {
            return this;
        }

        if (!(this.gateway = gateway)) {
            return this;
        }

        let configFile;

        try {
            configFile = (await import(`./Configs/${this.gateway}.json`)).default;
        } catch (e) {
            configFile = (await import(`./Configs/${PAYMENT_DEFAULT.gateway}.json`)).default;
        }

        let {info, data} = configFile;

        this.info['payment_gateway'] = info['payment_gateway'] || this.gateway;
        this.info['payment_gateway_type'] = info['payment_gateway_type'] || this.gatewayType;

        this.setConfig({
            ...info,
            ...data
        })

        return this;
    }

    mergeConfig(config = {}) {
        this.setConfig({
            ...this.config,
            ...config
        });

        return this;
    }

    setConfig(config = {}) {
        this.config = config

        this.config['project'] = ("project" in this.config) ? this.config['project'] : (this.info["project"] || undefined);
        this.config['api_key'] = ("api_key" in this.config) ? this.config['api_key'] : (this.info["api_key"] || undefined);
        this.config['is_live'] = ("is_live" in this.config) ? this.config['is_live'] : (this.info["is_live"] || 0);

        return this;
    }

    getConfig($key = undefined, $default = undefined) {
        if (!$key) {
            let config = Object.assign({}, this.config);
            if ("api_key" in config) {
                delete config['api_key'];
            }
            return config;
        }

        let result;
        try {
            result = $key in this.config ? this.config[$key] : $default;
        } catch (e) {
            result = $default;
        }

        return result;
    }

    withConfig(data = {}) {
        return {
            ...this.getConfig(),
            ...data
        };
    }

    withBaseData(data = {}) {
        let {payment_gateway, project, is_live} = {...this.info, ...this.config};
        return {
            payment_gateway,
            project,
            is_live,
            ...data
        };
    }

    getAllData(data = {}) {
        return {
            ...this.info,
            ...this.config,
            ...data
        };
    }

    /**
     * Returns region, regions, currency, currencies or any region-related data from config/info.
     *
     * @returns {Promise<{regions: *|string[], currency: *, region: *|string, currencies: *|{[p: number]: *|string}}>}
     */
    getRegionConfig() {
        return this.loadInfo()
            .then(({region, regions, currency, currencies}) => {

                region = region || undefined;
                regions = regions || [];
                currency = currency || undefined;
                currencies = currencies || {[region]: currency};

                if (!regions) {
                    throw new Error(`No regions found!`);
                }

                if (!currencies) {
                    throw new Error(`No currencies found!`);
                }

                if (!region) {
                    throw new Error(`Region not set!`);
                }

                if (!regions.includes(region)) {
                    throw new Error(`Not allowed to use the given region [${region}]!`);
                }

                if (currency) {
                    if (!Object.values(currencies).includes(currency)) {
                        throw new Error(`Not allowed to use the given currency [${currency}]!`);
                    }

                    if (String(currencies[region] || "").trim() !== String(currency).trim()) {
                        throw new Error(`Current region doesn't support the given currency [${currency}]!`);
                    }
                } else {
                    if (!(region in currencies)) {
                        throw new Error(`The given region is not allowed to get currency [${region}]!`);
                    }
                    currency = currencies[region];

                    if (!Object.values(currencies).includes(currency)) {
                        throw new Error(`Not allowed to use the given currency [${currency}]!`);
                    }
                }

                return {
                    region,
                    regions,
                    currency,
                    currencies,
                };
            });
    }
    
    getAxios() {
        return this.loadInfo()
            .then(x => {
                this.axios = this.axios || $axios.makeAxios({debug: this.info.debug});

                let {data, key} = dataGet(this.info, [this.info && this.info.debug ? 'base_url_local' : 'base_url', 'base_url'], 'base_url');
                $axios.setAxiosToken(this.getConfig('api_key'), this.axios)
                $axios.setAxiosBaseUrl(this.info[key] || data, this.axios)

                return this.axios;
            })
    }

    async getDriver() {
        let type = (await this.loadConfig()).getConfig('payment_gateway'),
            o;

        try {
            o = (await import(`./Types/${type}.js`)).default;
        } catch (e) {
            console.error(80, this.getConfig())
            throw new Error(`Driver [${type}] is missing!`);
        }

        return o.make(this);
    }

    async proxy(method, ...args) {
        let gateway = await this.getDriver();

        return new Promise((resolve, reject) => {
            gateway[method](...args)
                .then((response) => {
                    let {status, data} = response;

                    let success = status >= 200 && status < 300 && Number(data['status'] || 0) === 1;
                    let results = (gateway[`${method}BuildResponse`] || (() => ({}))).call(gateway, success, data);
                    let callback = success && resolve || reject;
                    if (results instanceof Builder) {
                        const result = {
                            /** @returns {HTMLScriptElement} */
                            head: () => document.head.appendChild(result.get()),
                            /** @returns {HTMLScriptElement} */
                            body: () => document.body.appendChild(result.get()),
                            /** @returns {HTMLScriptElement} */
                            get: () => undefined,
                            /** @returns {Builder|MasterCardBuilder} */
                            builder: () => results,
                            /** @returns {Builder|MasterCardBuilder} */
                            setLoadCallback: (callback) => {
                                results.setLoadCallback(callback);
                                return result;
                            },
                        };

                        callback(results
                            .prepare()
                            .then(script => {
                                result.get = () => script;
                                return result;
                            }));
                    } else {
                        callback(results);
                    }

                    return data;
                })
                .catch((error) => {

                    let results = (gateway[`${method}BuildResponse`] || (() => ({}))).call(gateway, false, error);

                    reject(results)
                    return results;
                })
        })
    }

    async initPayment(configurations = {}) {
        return await this.proxy('initPayment', ...arguments);
    }

    async registerPayment(body = {}) {
        return await this.proxy('registerPayment', ...arguments);
    }
}

export async function Noon(gatewayType = "Hosted", config = {}) {
    return new PaymentGatewaySDK({gateway: "Noon", gatewayType, config})
}

export async function HyperPay(gatewayType = "Hosted", config = {}) {
    return new PaymentGatewaySDK({gateway: "HyperPay", gatewayType, config})
}

export async function Urway(gatewayType = "Hosted", config = {}) {
    return new PaymentGatewaySDK({gateway: "Urway", gatewayType, config})
}

export async function MasterCard(gatewayType = "Hosted", config = {}) {
    return new PaymentGatewaySDK({gateway: "MasterCard", gatewayType, config})
}
